<template>
<div class="">
  <div v-for="(opt, inx) in options" :key="inx" :class="`option relative text-sm p-3 ${selected == opt.value ? 'bg-black text-white' : 'bg-white text-black hover:bg-black hover:text-white' } ${inx < options.length - 1 ? selected == opt.value || hoverOption == inx && currentOption - 1 == inx ? 'border-b-2 border-white' : 'border-b-2 border-black' : '' } ${hideOption(opt) ? 'hidden border-0' : ''}`" v-on:mouseover="setHoverOption(inx)" v-on:mouseout="setHoverOption(inx)">
    <input
      type="radio"
      class="appearance-none ignore-me block w-full inset-0 cursor-pointer outline-none"
      :id="opt.value"
      :name="name"
      v-on:click="selectOption(opt)" />
    <label class="block ignore-me" :for="opt.value" v-html="opt.title" />
  </div>
</div>
</template>

<script>
export default {
  props: [
    'name',
    'model',
    'selected',
    'options',
    'handleUpdate',
    'handleClose'
  ],
  data() {
    return {
      hoverOption: null,
      active: false
    }
  },
  computed: {
    currentOption() {
      let index = null
      this.options.map((opt, inx) => {
        if(opt.value == this.selected) {
          index = inx
        }
      })
      return index
    }
  },
  mounted() {
    this.active = true
    setTimeout(this.listenForClose, 500)
  },
  methods: {
    hideOption(opt) {
      let hideme = false
      if(opt.hidefor) {
        opt.hidefor.map(h => {
          if(h.values.indexOf(this.model[h.name]) > -1) {
            hideme = true
          }
        })
      }
      return hideme
    },
    setHoverOption(i) {
      this.hoverOption = i
    },
    selectOption(opt) {
      this.handleUpdate(opt, true)
    },
    listenForClose() {
      if(this.active) {
          document.getElementById('__layout').addEventListener('click', this.closeSelf)
      }
    },
    closeSelf(e) {
      if(e.target.className.indexOf('ignore-me') < 0) {
        this.handleClose(false)
      }
    }
  },
  beforeDestroy() {
    this.active = false
    document.getElementById('__layout').removeEventListener('click', this.closeSelf)
  }
}
</script>

<style lang="scss" scoped>
input {
  position: absolute!important;
  border-bottom: none!important;
  padding: 0!important;
}
</style>