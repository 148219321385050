// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/icons/caret-left-black.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/icons/caret-right-black.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/icons/caret-left.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/icons/caret-right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".selected[data-v-70895b71]{background:#000;color:#fff}.invalid[data-v-70895b71]{opacity:.5}.prev-arrow[data-v-70895b71]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:50%}.next-arrow[data-v-70895b71]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:50%}.calendar-main.color-purple .prev-arrow[data-v-70895b71]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.calendar-main.color-purple .next-arrow[data-v-70895b71]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.color-purple .selected[data-v-70895b71]{background:#fff;color:#8a0ffe}", ""]);
// Exports
module.exports = exports;
