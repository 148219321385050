<template>
  <div class="relative">
    <!-- <div class="absolute inset-0 cursor-pointer" v-on:click="toggleDropdown(!showDropdown)" /> -->
    <input class="select cursor-pointer" type="text" :id="field.name" :name="field.name" :placeholder="field.label" v-model="keyValue" autocomplete="off" v-on:focus="toggleDropdown(true)" v-on:keydown="handleNavigation" />
    <div v-if="showDropdown" :class="`dropdown absolute uppercase w-full bg-white border-black border-2 z-20`">
      <div v-for="(opt, i) in availableOptions" :key="i">
        <div v-if="availableOptions.length > 1 && opt.title" :class="`p-2 border-b-2 border-black font-black ${i > 0 ? 'border-t-2' : ''}`">{{ opt.title }}</div>
        <default-options :name="field.name" :options="opt.values" :model="model" :selected="model[field.name]" :handleUpdate="onUpdate" :handleClose="onClose" />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultOptions from './Options'
import moment from 'moment'

export default {
  components: {
    DefaultOptions
  },
  props: [
    'field',
    'model',
    'handleUpdate'
  ],
  data() {
    return {
      showDropdown: false,
      hoverOption: null
    }
  },
  computed: {
    availableOptions() {
      let options = []
      this.field.options.map(o => {
        let option = {
          title: o.title,
          values: []
        }
        o.values.map(v => {
          if(!this.field.depend) {
            option.values.push(v)
          } else if(this.model[this.field.depend] != null) {
            switch(this.field.dependOn) {
              default: //day
                if(v.valid.indexOf(moment(this.model[this.field.depend]).day()) > -1) {
                  option.values.push(v)
                }
            }
          }
        })
        options.push(option)
      })
      return options
    },
    keyValue() {
      let value = null
      console.log(this.field)
      this.field.options.map(o => {
        o.values.map(v => {
          if(this.model[this.field.name] == v.value) {
            value = v.title
          }
        })
      })
      return value
    }
  },
  methods: {
    getLabel() {
      let text = this.label
      this.availableOptions.map(opt => {
        opt.values.map(val => {
          if(this.model[this.field.name] == val.value) {
            text = val.title
          }
        })
      })
      return text
    },
    toggleDropdown(show) {
      this.showDropdown = show
    },
    handleNavigation(e) {
      if(e.key == 'Tab') {
        this.toggleDropdown(false)
      } else {
        e.preventDefault()
        if(e.key == 'ArrowDown') {
          this.cycleSelected(1)
        } else if(e.key == 'ArrowUp') {
          this.cycleSelected(-1)
        }
      }
    },
    cycleSelected(d) {
      let m = [0, 0]
      if(this.model[this.field.name] != null) {
        this.availableOptions.map((o, i) => {
          o.values.map((v, j) => {
            if(this.model[this.field.name] == v.value) {
              m = [i, j]
            }
          })
        })
        m[1] = m[1] + d
        if(m[1] >= this.availableOptions[m[0]].values.length) {
          if(m[0] <= this.availableOptions.length - 2) {
            m = [m[0] + 1, 0]
          } else {
            m = [0, 0]
          }
        } else if(m[1] < 0) {
          if(m[0] >= 1) {
            m = [m[0] - 1, this.availableOptions[m[0] - 1].values.length - 1]
          } else {
            m = [this.availableOptions.length - 1, this.availableOptions[this.availableOptions.length - 1].values.length - 1]
          }
        }
      }
      this.handleUpdate(this.field.name, this.availableOptions[m[0]].values[m[1]].value)
    },
    onUpdate(option, close = false) {
      this.handleUpdate(this.field.name, option.value)
      if(close) {
        this.toggleDropdown(false)
      }
    },
    onClose() {
      this.showDropdown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  margin-top: -2px;
  max-height: 320px;
  overflow-y: scroll;
}
.select {
  padding-right: 20px;
}
</style>