<template>
<div :class="`calendar-main p-2 pt-4 ${color ? `color-${color}` : 'color-white'}`">
  <div class="w-full flex items-center mb-2">
    <a href="#prevMonth" class="prev-arrow ignore-me w-6 h-6" title="Previous Month" v-on:click.prevent="prevMonth()" />
    <div class="ignore-me flex-grow text-center">{{ shown.format('MMMM YYYY') }}</div>
    <a href="#nextMonth" class="next-arrow ignore-me w-6 h-6" title="Next Month" v-on:click.prevent="nextMonth()" />
  </div>
  <div class="w-full px-1">
    <table class="w-full text-center">
      <thead>
        <tr>
          <th
            v-for="d in days"
            :key="d.index"
            class="ignore-me text-xs py-2" v-html="d" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="w in weeks" :key="w.index">
          <td
            v-for="(d, i) in w"
            :key="i"
            :class="`ignore-me text-xs py-2 ${d && d.available == false ? 'invalid' : 'cursor-pointer'} ${d && d.selected == true ? 'selected' : ''}`"
            v-html="d ? d.date.date() : ''"
            v-on:click="updateDate(d)" />
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
  props: [
    'color',
    'name',
    'minimum',
    'disableDayOfWeek',
    'selected',
    'handleUpdate',
    'handleClose',
  ],
  data() {
    return {
      days: ['SU', 'M', 'T', 'W', 'TH', 'F', 'S'],
      shown: moment(),
      weeks: [[]],
      active: false
    }
  },
  mounted() {
    this.shown = this.selected ? moment(this.selected) : moment()
    this.updateCalendar()
    this.active = true
    setTimeout(this.listenForClose, 500)
  },
  methods: {
    updateCalendar() {
      let minimum = this.minimum ? moment().add(this.minimum, 'days') : moment()
      let weeks = [[]]
      let firstDay = moment([this.shown.year(), this.shown.month(), 1]).format('e')
      while(firstDay > 0) {
        weeks[weeks.length - 1].push(null)
        firstDay -= 1
      }
      let day = 1
      while(day <= this.shown.daysInMonth()) {
        if(weeks[weeks.length - 1].length >= 7) {
          weeks.push([])
        }
        let date = moment([this.shown.year(), this.shown.month(), day])
        weeks[weeks.length - 1].push({
          name: this.name,
          date: date,
          selected: moment(date.format('YYYY-MM-DD')).isSame(moment(this.selected).format('YYYY-MM-DD')),
          available: moment(date.format('YYYY-MM-DD')).add(1, 'day').isAfter(minimum) && (!this.disableDayOfWeek || moment(date.format('YYYY-MM-DD')).day() != this.disableDayOfWeek)
        })
        day += 1
      }

      this.weeks = weeks
    },
    updateDate(d) {
      if(d && d.available) {
        this.handleUpdate(d, true)
        this.updateCalendar()
      }
    },
    prevMonth() {
      let prev = moment(this.shown).subtract(1, 'month')
      if(!moment([prev.year(), prev.month(), 1]).isBefore(moment([moment().year(), moment().month(), 1]))) {
        this.shown = moment(this.shown).subtract(1, 'month')
        this.updateCalendar()
      }
    },
    nextMonth() {
      this.shown = moment(this.shown).add(1, 'month')
      this.updateCalendar()
    },
    listenForClose() {
      if(this.active) {
        document.getElementById('__layout').addEventListener('click', this.closeSelf)
      }
    },
    closeSelf(e) {
      if(e.target.className.indexOf('ignore-me') < 0) {
        this.handleClose(false)
      }
    }
  },
  beforeDestroy() {
    this.active = false
    document.getElementById('__layout').removeEventListener('click', this.closeSelf)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables';

.selected {
  background: black;
  color: white;
}

.invalid {
  opacity: .5;
}

.prev-arrow {
  background: url('../../assets/img/icons/caret-left-black.svg') no-repeat center center;
  background-size: 50%;
}

.next-arrow {
  background: url('../../assets/img/icons/caret-right-black.svg') no-repeat center center;
  background-size: 50%;
}

.calendar-main {
  &.color-purple {
    .prev-arrow {
      background-image: url('../../assets/img/icons/caret-left.svg');
    }
    .next-arrow {
      background-image: url('../../assets/img/icons/caret-right.svg');
    }
  }
}

.color-purple {
  .selected {
    background: white;
    color: $purple;
  }
}
</style>