<template>
<div>
  <div v-if="field.options.length > 1">
    <select v-model="model[field.name]">
      <option disabled :value="null">{{ field.label }}</option>
      <optgroup v-for="(opt, i) in availableOptions" :key="i" :label="opt.title">
        <option v-for="v in opt.values" :key="v.index" :value="v.value" v-html="v.title" />
      </optgroup>
    </select>
  </div>
  <div v-else>
    <select v-model="model[field.name]">
      <option disabled :value="null">{{ field.label }}</option>
      <option v-for="v in availableOptions[0].values" :key="v.index" v-if="!hideOption(v)" :value="v.value" v-html="v.title" />
    </select>
  </div>
</div>
</template>

<script>
export default {
  props: [
    'field',
    'model',
    'handleUpdate'
  ],
  computed: {
    availableOptions() {
      let options = []
      this.field.options.map(o => {
        let option = {
          title: o.title,
          values: []
        }
        o.values.map(v => {
          if(!this.field.depend) {
            option.values.push(v)
          } else if(this.model[this.field.depend] != null) {
            switch(this.field.dependOn) {
              default: //day
                if(v.valid.indexOf(moment(this.model[this.field.depend]).day()) > -1) {
                  option.values.push(v)
                }
            }
          }
        })
        options.push(option)
      })
      return options
    },
    keyValue() {
      let value = null
      this.field.options.map(o => {
        o.values.map(v => {
          if(this.model[this.field.name] == v.value) {
            value = v.title
          }
        })
      })
    return value
    }
  },
  methods: {
    hideOption(opt) {
      let hideme = false
      if(opt.hidefor) {
        opt.hidefor.map(h => {
          if(h.values.indexOf(this.model[h.name]) > -1) {
            hideme = true
          }
        })
      }
      return hideme
    },
    onUpdate() {
      this.handleUpdate(this.field.name, this.model)
    }
  }
}
</script>